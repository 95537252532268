export const ORDER_FULL_FRAGMENT = `
  fragment orderFullFragment on Order {
    uid
    id
    totalPrice
    status
    currency
    paymentIntentId
    creationTime
    paymentIntentId
    orderPackages {
      id
      uid
      quantity
      salesPackage {
       uid
       title
       price
       maximumQuantity
       _stock"%filter%" {
          uid
          quantity
      }
       salesPackageComponent {
        id
        uid
        salesComponent {
          uid
          title
          type
          strategy
        }
      }
    }
    }
    exhibitor {
      uid
      name
    }
  }
`;
